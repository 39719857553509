import Script from 'next/script'
import { isProductionEnv } from 'src/helpers'

import { USE_FIREBASE_EMULATOR } from '../../constants'

export default function Intercom({ enabled = true }: { enabled: boolean }) {
  return enabled && isProductionEnv() && !USE_FIREBASE_EMULATOR ? (
    <Script
      id='intercom-script'
      dangerouslySetInnerHTML={{
        __html: `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/qg0pl3tr';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
      }}
    ></Script>
  ) : null
}
