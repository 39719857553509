import 'src/polyfills'
import 'styles/global.css'

import memoize from 'lodash/memoize'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'
import { RecoilRoot } from 'recoil'
import Hotjar from 'src/components/Hotjar'
import Intercom from 'src/components/Intercom'
import { UserProvider } from 'src/contexts/UserContext'

// ignore in-browser next/js recoil warnings until its fixed.
const mutedConsole = memoize(console => ({
  ...console,
  warn: (...args: (string | string[])[]) =>
    args[0].includes('Duplicate atom key') ? null : console.warn(...args),
}))
global.console = mutedConsole(global.console)

function MyApp({ Component, pageProps }: AppProps) {
  const props: {
    [key: string]: any
    disableNProgress?: boolean
    disableIntercom?: boolean
    disableHotjar?: boolean
  } = {
    ...pageProps,
  }
  useEffect(() => {
    if (props.disableNProgress) return
    Router.events.on('routeChangeStart', () => NProgress.start())
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())
  }, [props.disableNProgress])

  return (
    <RecoilRoot>
      <UserProvider>
        <Head>
          <meta charSet='utf-8' />
          <meta
            name='viewport'
            content='initial-scale=1.0, width=device-width'
          />
          <link
            rel='shortcut icon'
            type='image/png'
            href='https://cdn.arcade.software/images/button-logo-128.png'
          />
          <link
            rel='apple-touch-icon'
            href='https://cdn.arcade.software/images/button-logo-128.png'
          />
          <meta
            name='msapplication-TileImage'
            content='https://cdn.arcade.software/images/button-logo-128.png'
          ></meta>
        </Head>
        <Intercom enabled={!props.disableIntercom} />
        <Hotjar enabled={!props.disableHotjar} />

        <Component {...pageProps} />
      </UserProvider>
    </RecoilRoot>
  )
}

export default MyApp
