import { Step, StepType } from 'src/types'

export function getStepPreviewUrl(step: Step | undefined): string {
  if (!step) {
    return ''
  }
  if (step.type === StepType.Image) {
    return step.url || step.imageUrl || ''
  }

  if (step.type === StepType.Video) {
    return (
      step.videoThumbnailUrl ||
      (step.streamUrl
        ? step.streamUrl
            .replace('stream', 'image')
            .replace('.m3u8', '/thumbnail.png')
        : '')
    )
  }

  return ''
}
