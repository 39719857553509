import Script from 'next/script'
import { isProductionEnv } from 'src/helpers'

import { USE_FIREBASE_EMULATOR } from '../../constants'

export default function Hotjar({ enabled = true }: { enabled: boolean }) {
  return enabled && isProductionEnv() && !USE_FIREBASE_EMULATOR ? (
    <Script
      id='hotjar-script'
      dangerouslySetInnerHTML={{
        __html: `(function(h,o,t,j,a,r){h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };h._hjSettings={hjid:3291440,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
      }}
    ></Script>
  ) : null
}
