import { setUser } from '@sentry/nextjs'
import { createContext, PropsWithChildren, useEffect } from 'react'
import { passUserToExtension } from 'src/helpers'
import { User } from 'src/types'

// see src/hocs/withUser.tsx for details
// the actual user is loaded in `withUser`
export const defaultUser: User = {
  id: null,
  isLoggedIn: false,
  name: null,
  email: null,
  emailVerified: false,
  emailDomain: null,
  photoURL: null,
  getIdToken: async () => null,
  clientInitialized: false,
  firebaseUser: null,
  signOut: async () => {},
  group: null,
  intercomUserId: null,
  intercomUserHash: null,
  prefs: null,
  features: null,
  currentSubscriber: false,
  customerId: null,
  subscriptionDate: null,
  activeTeamId: null,
  isActiveMemberOfTeam: false,
  isExternalMemberOfTeam: false,
  isTeamAdmin: false,
  hasActiveInvitationToTeam: false,
  isFreeUser: true,
  isProUser: false,
  onboardingUseCases: {
    Marketing: false,
    Product: false,
    Sales: false,
    CustomerSuccess: false,
    Other: null,
  },
  onboardingCompanySize: null,
  getStartedArticlesHidden: null,
}

export const UserContext = createContext(defaultUser)

export function UserProvider({
  user,
  children,
}: PropsWithChildren<{ user?: User }>) {
  useEffect(() => {
    if (user?.id) {
      setUser({ id: user.id })
      passUserToExtension({
        id: user.id,
        email: user.email,
        displayName: user.name,
      })
      ;(window as any).hj?.('identify', user.id, {})
    }
  }, [user])
  return (
    <UserContext.Provider value={user ?? defaultUser}>
      {children}
    </UserContext.Provider>
  )
}
